const b = {
  sm: `750px`, // Tablet
  lg: `1186px`, // Small desktop
  xl: `1920px`, // Big desktop
}

export const breakpoints = b

const bp = []
for (const b in breakpoints) {
  bp.push(breakpoints[b])
}

export const breakpointsArray = bp
