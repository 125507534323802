import {
  breakpointsArray as breakpoints,
  colors as colorsTokens,
  fontWeights,
  mediaQueries,
  transition,
} from "./index"

const c = {
  ...colorsTokens,
  background: colorsTokens.white,
  primary: colorsTokens.code.primary,
  secondary: colorsTokens.code.secondary,
}

export const theme = {
  colors: c,
  breakpoints,
  mediaQueries,
  transition,
  fontWeights,
}

export { c as colors, breakpoints, fontWeights, mediaQueries, transition }
