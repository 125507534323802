import preval from "preval.macro"

// we need to wrap this file inside preval to retain tree-shaking.
// using syntaxes like const x = { ...var } adds side-effects to the export
// preval precompiles it so we just return a single object.

export const colors = preval`
  const palette = {
    white: '#ffffff',
    black: '#000000',
  }


  const primary =  '#ffa41b'
  const secondary = '#100720'
  const footerText = '#e1e1e1'


  module.exports = {
    ...palette,   
    code: {
      primary,
      secondary,
      footerText,
    },
  }
`
