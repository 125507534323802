// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-seelk-fr-js": () => import("./../../../src/pages/about-seelk.fr.js" /* webpackChunkName: "component---src-pages-about-seelk-fr-js" */),
  "component---src-pages-about-seelk-js": () => import("./../../../src/pages/about-seelk.js" /* webpackChunkName: "component---src-pages-about-seelk-js" */),
  "component---src-pages-amazon-agency-fr-js": () => import("./../../../src/pages/amazon-agency.fr.js" /* webpackChunkName: "component---src-pages-amazon-agency-fr-js" */),
  "component---src-pages-amazon-agency-js": () => import("./../../../src/pages/amazon-agency.js" /* webpackChunkName: "component---src-pages-amazon-agency-js" */),
  "component---src-pages-amazon-analytics-software-fr-js": () => import("./../../../src/pages/amazon-analytics-software.fr.js" /* webpackChunkName: "component---src-pages-amazon-analytics-software-fr-js" */),
  "component---src-pages-amazon-analytics-software-js": () => import("./../../../src/pages/amazon-analytics-software.js" /* webpackChunkName: "component---src-pages-amazon-analytics-software-js" */),
  "component---src-pages-cookies-policy-fr-js": () => import("./../../../src/pages/cookies-policy.fr.js" /* webpackChunkName: "component---src-pages-cookies-policy-fr-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../../../src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-index-fr-js": () => import("./../../../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-notice-fr-js": () => import("./../../../src/pages/legal-notice.fr.js" /* webpackChunkName: "component---src-pages-legal-notice-fr-js" */),
  "component---src-pages-legal-notice-js": () => import("./../../../src/pages/legal-notice.js" /* webpackChunkName: "component---src-pages-legal-notice-js" */),
  "component---src-pages-product-fr-js": () => import("./../../../src/pages/product.fr.js" /* webpackChunkName: "component---src-pages-product-fr-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-success-stories-fr-js": () => import("./../../../src/pages/success-stories.fr.js" /* webpackChunkName: "component---src-pages-success-stories-fr-js" */),
  "component---src-pages-success-stories-js": () => import("./../../../src/pages/success-stories.js" /* webpackChunkName: "component---src-pages-success-stories-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

